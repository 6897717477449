<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="48">
                    <v-toolbar flat>
                        <v-btn
                            outlined
                            class="mr-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            Hoje
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                            class="mr-4"
                        >
                            <v-icon small>
                            mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu
                            bottom
                            right
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                mdi-menu-down
                                </v-icon>
                            </v-btn>
                            </template>
                            <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Dia</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mês</v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet :height="windowSize.y - 64 - 216" class="pa-4 rounded-b-lg">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="filteredCalendarEvents ? filteredCalendarEvents : calendarEvents"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        :search="search"
                    ></v-calendar>

                    <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        offset-x
                    >
                        <v-card
                            color="grey lighten-4"
                            min-width="350px"
                            flat
                        >
                            <v-toolbar
                                :color="selectedEvent.color"
                                dark
                            >
                                
                                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="editEvent(selectedEvent)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="deleteItem()">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn icon @click="selectedOpen = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <span class="text-subtitle-2 mr-2">Evento: </span>
                                <span class="text-body-1">{{ selectedEvent.event }}</span>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>
     </v-layout>
</template>

<script>
export default {
    props: {
        endpoint: { type: Array, required: true },
        options: { type: Array, required: true },
        calendarEvents: { type: Array, required: true },
        events: { type: Array, required: true },
        defaultItem: { type: Array, required: true },
        search: { type: String, required: false, default: null }
    },

    computed: {
        filteredCalendarEvents() {
            if (this.search) {
                return this.calendarEvents.filter(c => c.name.includes(this.search))
            } else {
                return null
            }
        }
    },

    data() {
        return {
            focus: '',
            type: 'month',
            typeToLabel: {
                day: 'Dia',
                month: 'Mês',
                week: 'Semana'
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,

            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

            windowSize: { x: 0, y: 0 },
        }
    },

    methods: {
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },

        getEventColor (event) {
            return event.color
        },

        setToday () {
            this.focus = ''
        },

        prev () {
            this.$refs.calendar.prev()
        },

        next () {
            this.$refs.calendar.next()
        },

        deleteItem() {
            let found = this.events.find(e => e.id_event_calendar === this.selectedEvent.id_event_calendar)
            this.$emit('deleteItem', found.id_event_calendar)
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        editEvent(event) {
            let found = this.events.find(e => e.id_event_calendar === event.id_event_calendar)

            let editedItem = []
            this.defaultItem.forEach(f => {
                editedItem.push({
                    ...f,
                    value: found[f.name] || null,
                })
            })

            this.$emit('editItem', editedItem)
            this.selectedOpen = false
        },

        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
    },
  }
</script>

<style scoped>
.v-calendar-weekly__day-label .v-btn {
    max-width: 24px !important;
    max-height: 24px;
    margin-bottom: 4px;
    font-size: 10px;
}
</style>
