<template>
<div id="EventCalendar">
    <v-container fluid app>
        <v-sheet class="rounded-t-lg">
            <v-toolbar flat class="rounded-t-lg">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary">
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <div class="d-flex flex-column align-start">
                    <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                </div>

                <v-spacer></v-spacer>

                <v-text-field
                    type="text"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.searchLabel')"
                    hide-details
                    single-line
                    dense
                    clearable
                    class="mr-4"
                    style="width: 1%;"
                />

                <v-tooltip top v-if="filterConditions.length > 0">
                    <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="setFilterConditions([])"
                            >
                                <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                    </template>
                    <span>Limpar filtros</span>
                </v-tooltip>

                <v-tooltip top v-if="selected.length === 1 && createPermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="duplicateSelectedItem()"
                        >
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                    </template>
                    <span>Duplicar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selected.length === 1 && updatePermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="editSelectedItem()"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selected.length > 0 && deletePermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="dialogMassDelete = true"
                        >
                            <v-icon>mdi-delete-sweep</v-icon>
                        </v-btn>
                    </template>
                    <span>Deletar itens selecionados</span>
                </v-tooltip>

                <v-dialog v-model="dialogMassDelete" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Tem certeza de que deseja excluir estes itens?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeMassDelete" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="massDeleteItemConfirm" :loading="massDeleting">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                <v-tooltip top v-if="createPermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="dialog = true"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo item</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="refresh()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            :class="{ 'primary': !editedItems.length, 'secondary': editedItems.length }"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="massSave()"
                            :disabled="!editedItems.length"
                        >
                            <v-icon> {{ editedItems.length ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{ editedItems.length ? 'Salvar edições' : 'Nenhum dado foi alterado'}}
                    </span>
                </v-tooltip>

                <v-tooltip top v-if="crudPermission">
                    <template v-slot:activator="{on}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary" dark
                            v-on="on"
                            class="mb-2 pa-0"
                            min-width="48px"
                            @click="editedItems.length ? dialogCloseTable = true : $router.push('/')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mx-4 mb-2"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center mb-1 px-4" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs v-model="tab" height="24px">
                        <v-tabs-slider color="primary"></v-tabs-slider>

                        <v-tab v-for="item in tabItems" :key="item">
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <ZnapFilters ref="znapFilters"
                :filterComponents="options"
                :noData="noData"
                @setSelection="populateFilterConditions"
                :clearFilters="clearFilters"
                @setClearFilters="setClearFilters"
            >
            </ZnapFilters>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <ZnapTable ref="znapTable"
                        :endpoint="endpoint"
                        :options="options"
                        :tableRows="tableRows"
                        :noData="noData"
                        @setNoData="setNoData"
                        :noDataMessage="noDataMessage"
                        @setNoDataMessage="setNoDataMessage"
                        :filterConditions="filterConditions"
                        @setFilterConditions="setFilterConditions"
                        @clearFilters="setFilterConditions"
                        :loadTable="loadTable"
                        @setLoadTable="setLoadTable"
                        :clearTableSelected="clearTableSelected"
                        @setClearTableSelected="setClearTableSelected"
                        @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
                        :showToolbar="false"
                        @editItem="editItem"
                        @deleteItem="deleteItem"
                        @setSelectedItem="setSelectedItem"
                        @setEditedItems="setEditedItems"
                        :searchProp="search"
                    >
                    </ZnapTable>
                </v-tab-item>

                <v-tab-item>
                    <ZnapCalendar
                        :events="events"
                        :calendarEvents="calendarEvents"
                        :endpoint="endpoint"
                        :options="options"
                        :defaultItem="defaultItem"
                        :search="search"
                        @editItem="editItem"
                        @deleteItem="deleteItem"
                    >
                    </ZnapCalendar>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-dialog v-model="dialogCloseTable" max-width="600px">
            <v-card>
                <div class="d-flex justify-center">
                    <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                </div>
                <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                    <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEditItem" max-width="600px">
            <v-card>
                <div class="d-flex justify-center">
                    <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja prosseguir?</v-card-title>
                </div>
                <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogEditItem = false, close()" class="mr-4">Cancelar</v-btn>
                    <v-btn color="primary" @click="dialog = true, dialogEditItem = false">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
                <div class="d-flex justify-center">
                    <v-card-title class="headline text-center">Tem certeza de que deseja excluir este evento?</v-card-title>
                </div>
                <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogDelete = false" class="mr-4">Cancelar</v-btn>
                    <v-btn color="primary" @click="deleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="900px">
            <v-card style="position: relative;">
                <v-card-title class="light--text"
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                >
                    <span ref="formTitle" class="headline">{{ formTitle }}</span>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn v-on="on"
                                class="pa-0 mr-2"
                                @click="returnEditedItemToDefault()"
                                min-width="48px"
                                text dark
                            >
                                <v-icon color="light">mdi-restart</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar campos</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn v-on="on"
                                class="pa-0"
                                @click="closeDialog"
                                min-width="48px"
                                text dark
                            >
                                <v-icon color="light">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <ZnapDialog
                            :editedItem="editedItem"
                            :editedIndex="editedIndex"
                            :disabled="disabled"
                            :options="options"
                            :filterConditions="filterConditions"
                        ></ZnapDialog>
                    </v-container>
                </v-card-text>
                <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                    <v-divider class="mx-4"></v-divider>
                    <v-card-actions class="px-6 py-6">
                        <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="closeDialog"
                            class="mr-4"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="primary"
                            :loading="saving"
                            :disabled="disabled"
                            @click="save()"
                        >
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import Vue from 'vue'
import ZnapFilters from '@/components/znap/Filters.vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapCalendar from '@/components/znap/ZnapCalendar.vue'
import ZnapDialog from '@/components/znap/ZnapDialog.vue'
import moment from 'moment'
import lodash from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name:'EventCalendar',

    components: { ZnapFilters, ZnapTable, ZnapCalendar, ZnapDialog },

    computed: {
        ...mapGetters('auth', ['getMenu']),

        endpoint() {
            return [
                this.$ipEvent,
                'event-calendar',
                {
                    conditions: []
                }
            ]
        },

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        formTitle() {
            return this.editedIndex <= 0 ? 'Novo item' : 'Editar item'
        },

        disabled() {
            let validation = []

            this.editedItem.forEach((item) => {
                if (item.rules.includes('required') && !item.hide) {
                    if (item.value === null || item.value === '') {
                        validation.push('disabled')
                    }
                }
            })

            return validation.includes('disabled') ? true : false
        },
    },

    data() {
        return {
            tableRows: 10000,
    
            tab: 0,
            tabItems: ['Tabela', 'Calendário'],

            events: [],
            calendarEvents: [],

            search: '',
    
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ],
                    filter: {
                        orderColumn: 'description',
                        textColumn: 'description',
                        conditions: []
                    }
                },
                {
                    column: 'id_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo de usuários',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipSecurity, 'group' ],
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 14
                        // }
                    ]
                },


                {
                    column: 'id_status',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Status',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_status_type',
                            operator: '=',
                            value: 1
                        }
                    ]
                },
                {
                    column: 'id_planning_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo de planejamento',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ],
                    conditions: []
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                    conditions: []
                },
                {
                    column: 'id_purpose',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Propósito',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ],
                    conditions: []
                },
                {
                    column: 'id_approval_flow_level',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Nível do fluxo de aprovação',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'approval-flow-level' ],
                    conditions: []
                }
            ],
    
            noDataMessage: '',
            noData: true,

            dialog: false,
            dialogDelete: false,
            dialogCloseTable: false,
            dialogEditItem: false,
            deleting: false,
            editedIndex: -1,
            editedItem: [],
            defaultItem: [],
            loading: false,
            saving: false,
            massDeleting: false,
            selected: [],
            dialogMassDelete: false,
            editedItems: [],
            exporting: false,
            importing: false,
    
            // Filters
            filterConditions: [],
            loadTable: false,
            clearTableSelected: false,
            clearFilters: false,

            crudPermission: true,
            createPermission: true,
            updatePermission: true,
            deletePermission: true,
        }
    },

    beforeMount() {
        this.load()
    },

    created() {
        let routePath = this.$route.path
        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        async load() {
            this.events =  []
            this.calendarEvents = []

            try {
                let payload = {
                    filter: {
                        conditions: []
                    }
                }

                this.filterConditions.forEach(condition => {
                    payload.filter.conditions.push(condition)
                })

                const res = await this.$http.post(`${this.endpoint[0]}${this.endpoint[1]}/list`, { ...payload })
                if (res) {
                    let events = null
                    let filteredEvents = null

                    if (this.search) {
                        filteredEvents = res.data.rows.filter(e => {
                            return e.description.includes(this.search) 
                        })
                        events = filteredEvents
                    } else {
                        events = res.data.rows
                    }

                    let headers = this.setHeaders(res.data.headers, res.data.columns)
                    this.defaultItem = this.setDefaultFields(headers)
                    this.editedItem = this.setDefaultFields(headers)

                    for (let i = 0; i < events.length; i++) {
                        this.calendarEvents.push({
                            id_event_calendar: events[i].id_event_calendar,
                            name: events[i].description,
                            start: moment.utc(events[i].start_date).format("YYYY-MM-DD"),
                            end: events[i].end_date ? 
                                moment.utc(events[i].end_date).format("YYYY-MM-DD")
                                : moment.utc(events[i].start_date).format("YYYY-MM-DD"),
                            color: events[i].color ? events[i].color : 'blue',
                            timed: false,
                            event: events[i].event
                        })
                    }
                    
                    this.events = events
                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        setHeaders(headers, columns) {
            let _headers = []

            headers.forEach((header, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(header.value)

                if (index === -1) {
                    index = i
                }

                if (header.width !== undefined ) {
                    width = header.width
                } else {
                    width = "1%"
                }

                if (header.type !== undefined) {
                    type = header.type
                } else {
                    if (header.file) {
                        type = 'IMG'
                    } else {
                        type = columns[index].columnType
                    }
                }

                if (header.rules !== undefined) {
                    rules.push(header.rules)
                } else {
                    let columnIdIndex = index

                    if (header.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(header.columnId)
                    }

                    if (columns[columnIdIndex].nullColumn === 'NO') {
                        rules.push('required')
                    }
                }

                // If header hide is true table column will show but will not appear at edit dialog
                if (header.hide !== undefined) {
                    hide = header.hide
                } else {
                    if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                        hide = true
                        key = columns[index].key
                    }
                }
                
                if (header.length !== undefined) {
                    length = header.length
                } else {
                    length = columns[index].columnLength
                }
                
                if (!header.hideColumn) {
                    _headers.push({ ...header, width, type, rules, hide, key, length })
                }
            })

            return _headers
        },

        refresh() {
            this.loading = true

            // ZnapTable
            this.loadTable = true

            this.search = ''
            this.editedIndex = -1
            this.editedItem = []
            this.defaultItem = []
            this.dialog = false
            this.dialogDelete = false
            this.editedItems = []
            this.clearTableSelected = true
            this.selected = []

            this.load()
        },

        editItem(e) {
            this.editedItem = e
            this.editedIndex = e.find(i => i.name === 'id_event_calendar').value

            if (this.editedItems.length) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        setEditedItem(item) {
            this.defaultItem.forEach((defaultItem, index) => {
                if (defaultItem.edit !== false) {
                    if (defaultItem.name in item) {
                        if (item[defaultItem.name]) {
                            this.editedItem[index].value = item[defaultItem.name]
                        } else {
                            this.editedItem[index].value = null
                        }
                    }
                }
            })

            // remove defaultFieldsComplement if not editable
            this.editedItem.forEach((item, index) => {
                if (item.edit === false) {
                    this.editedItem.splice(index, 1)
                }
            })
        },

        editSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = item.id_event_calendar
            this.setEditedItem(item)

            this.dialog = true
        },

        duplicateSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = 0
            this.setEditedItem(item)
            
            this.dialog = true
        },

        deleteItem(e) {
            this.editedIndex = e
            this.dialogDelete = true
        },

        setSelectedItem(e) {
            this.selected = e
        },

        setEditedItems(e) {
            this.editedItems = e
        },

        async deleteItemConfirm () {
            this.deleting = true

            try {
                const res = await this.$http.delete(this.endpoint[0] + this.endpoint[1] + `/delete/${this.editedIndex}`, {})
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.dialogDelete = false
                    this.deleting = false

                    this.refresh()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async massDeleteItemConfirm() {
            this.massDeleting = true

            let payload = {
                delete: { items: [] },
            }

            payload.delete.items = this.selected

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                if (res) {
                    if (this.selected.length > 0) {
                        this.selected = []
                    }

                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
            
            this.closeMassDelete()
            this.massDeleting = false
            this.refresh()
        },

        closeMassDelete() {
            this.dialogMassDelete = false
        },

        async save() {
            this.saving = true
            let object = {}
            let id = null

            this.editedItem.forEach(item => {
                if (item.label === 'ID') {
                    id = item.value
                }
                object[item.name] = item.value
            })

            if (this.endpoint[2].conditions.length) {
                this.endpoint[2].conditions.forEach(condition => {
                    object[condition.column] = condition.value
                })
            }

            object.event = object.event.text

            if (this.editedIndex <= 0) {
                try {
                    const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/create', { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                object.start_date = object.start_date ? moment.utc(object.start_date).format("YYYY-MM-DD") : null
                object.end_date = object.end_date ? moment.utc(object.end_date).format("YYYY-MM-DD") : null

                try {
                    const res = await this.$http.put(this.endpoint[0] + this.endpoint[1] + `/edit/${id}`, { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }

            this.closeDialog()
            this.saving = false
            this.refresh()
        },

        async massSave() {
            if (this.editedItems.length) {
                let payload = {
                    edit: { items: this.editedItems },
                }

                try {
                    const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                    if (res) {
                        this.$toast.success(res.data.msg)
                        this.refresh()
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }
        },

        closeDialog() {
            this.dialog = false

            if (this.editedIndex > -1) {
                this.returnEditedItemToDefault()
                this.editedIndex = -1
            }
        },

        setDefaultFields(headers) {
            let defaultItem = []

            headers.forEach(header => {
                defaultItem.push({
                    name: header.value,
                    label: header.text,
                    value: null,
                    type: header.type,
                    rules: header.rules,
                    hide: header.hide,
                    key: header.key,
                    options: header.options,
                    columnId: header.columnId,
                    length: header.length,
                    columnType: header.columnType
                })
            })

            return defaultItem
        },

        returnEditedItemToDefault() {
            this.editedItem = lodash.cloneDeep(this.defaultItem)
        },

        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(e) {
            this.loadTable = e
        },

        setClearTableSelected(e) {
            this.clearTableSelected = e
        },

        setPayload(payloadComplement = {}, endpointFilter = {}) {
            let conditions = []
            if (endpointFilter.conditions) {
                conditions = [ ...this.filterConditions, ...endpointFilter.conditions ]
            } else {
                conditions = [ ...this.filterConditions ]
            }

            let checkboxConditions = {}
            let checkboxInConditions = []
            if (this.checkboxFilters) {
                this.checkboxFilters.forEach(f => {
                    let option = this.options.find(o => o.is === "Checkbox" && o.column === f.column)
                    
                    if (option.inConditions && f.value) {
                        checkboxInConditions.push({
                            AndOr: 'AND',
                            column: 'tag',
                            not: true,
                            operator: 'CONTAINS',
                            value: '_REPORT'
                        })
                    } else {
                        checkboxConditions[f.column] = f.value
                    }
                })
            }

            return {
                ...payloadComplement,
                ...checkboxConditions,
                filter: {
                    tableRows: this.tableRows,
                    conditions: [
                        ...conditions,
                        ...checkboxInConditions
                    ]
                }
            }
        },

        exportSheet(){
            this.exporting = true

            let payload = this.setPayload({}, this.endpoint[2])

            this.$http.post(this.endpoint[0] + this.endpoint[1] + '/report', { ...payload }) 
            .then((res) => {
                window.open(res.data.token)
                this.$toast.success(res.data.msg)
                this.exporting = false
            })
            .catch((err)=>{
                this.exporting = false
                this.$fnError(err)
            })
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/upload-spreadsheet',
                    body, { headers: { 'Content-Type': 'multipart/form-data' }}
                )
                .then((res)=>{
                    this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', {...res.data.id})
                    .then((res)=>{
                        this.$toast.success(res.data.msg)
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.refresh()
                    }).catch((err)=>{
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.$fnError(err)
                    })
                })
                .catch((err)=>{
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                })
            }
        },
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}
</style>
